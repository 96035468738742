import React from 'react';
import { graphql } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import { Grid, Text, Card } from '@paygreen/paygreen-ui';
import ClickableCard from '../components/ClickableCard';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import { parseGraphQL } from '../utils/parsers/questionsParser';
import SEO from '../components/seo';
import MostViewed from '../components/MostViewed';
import { sortPrismicItemByPriority } from '../utils/arrayHelper';
const activeEnv =
    process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development';
const tagNameToProduction =
    process.env.NAME_TAG_TO_QUESTION_PRODUCTION || 'valid-to-prod';

const ThemePage = ({ data }) => {
    const intl = useIntl();

    const theme = data.prismic.theme || null;
    if (!theme) {
        return null;
    }

    const colorTheme =
        theme.colorTheme === 'default' ? 'primary' : theme.colorTheme;
    const gradient = theme.colorTheme === 'default' ? 'brand' : 'theme';

    let categories = data.prismic.allCategorys.edges;
    if (categories.lenght === 0) {
        return null;
    }
    categories = sortPrismicItemByPriority(categories);

    // Array of most viewed questions for this theme
    const mostViewed = [];
    theme.mostViewed.forEach(({ question }) => {
        if (question === null) {
            console.info(`Erreur de lien sur la page ${theme.name}`);
        }
        if (
            (question !== null && question._meta &&
                question._meta.tags.includes(tagNameToProduction)) ||
            activeEnv === 'recette' ||
            activeEnv === 'development'
        ) {
            mostViewed.push(parseGraphQL(question));
        }
    });

    const {
        metaTitle,
        metaDescription,
        ogUrl,
        ogTitle,
        ogDescription,
        ogImage,
    } = data.prismic.theme;

    const breadcrumb = [
        {
            url: '/',
            label: intl.formatMessage({ id: 'home.title' }),
        },
        {
            url: '#',
            label: theme.breadcrumbLabel,
        },
    ];

    return (
        <Layout colorTheme={colorTheme}>
            <SEO
                metaTitle={metaTitle}
                description={metaDescription}
                ogTitle={ogTitle}
                ogDescription={ogDescription}
                ogImage={ogImage}
                ogUrl={ogUrl}
            />

            <Banner
                breadcrumb={breadcrumb}
                name={theme.name}
                description={theme.description}
                theme={colorTheme}
                gradient={gradient}
            />

            {categories.length === 0 ? (
                <Grid justifyContent="center">
                    <Card hasBackground={false} blockWidth="xl">
                        <Text align="center">
                            {intl.formatMessage({ id: 'theme.nocategories' })}
                        </Text>
                    </Card>
                </Grid>
            ) : (
                <Grid
                    displayType="grid"
                    columnNumber={3}
                    justifyItems="stretch"
                >
                    {categories.map(({ node }) => {
                        let link = {
                            name: null,
                            href:
                                '/' +
                                theme._meta.uid +
                                '/' +
                                node._meta.uid +
                                '/',
                        };

                        return (
                            <ClickableCard
                                data-testid="cc-category"
                                key={node._meta.uid}
                                name={node.name}
                                description={node.description}
                                link={link}
                                colorTheme={colorTheme}
                                borderTop={gradient}
                            />
                        );
                    })}
                </Grid>
            )}

            <MostViewed questions={mostViewed} colorTheme={colorTheme} />
        </Layout>
    );
};

export default ThemePage;

export const query = graphql`
    query($uid: String!, $id: String!) {
        prismic {
            theme(uid: $uid, lang: "fr-fr") {
                breadcrumbLabel
                name
                description
                colorTheme
                icon
                metaTitle
                metaDescription
                canonicalUrl
                ogUrl
                ogTitle
                ogDescription
                ogImage
                priority
                _meta {
                    uid
                }
                mostViewed {
                    question {
                        ... on PRISMIC_Question {
                            title
                            description
                            _meta {
                                uid
                                tags
                            }
                            parentCategory {
                                ... on PRISMIC_Category {
                                    _meta {
                                        uid
                                    }
                                    parentTheme {
                                        ... on PRISMIC_Theme {
                                            _meta {
                                                uid
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        prismic {
            allCategorys(where: { parentTheme: $id }) {
                edges {
                    node {
                        name
                        description
                        colorTheme
                        _meta {
                            uid
                            id
                        }
                    }
                }
            }
        }
    }
`;
