import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'gatsby-plugin-intl';
import { Divider, Grid, Card } from '@paygreen/paygreen-ui';
import QuestionList from './QuestionList';

const MostViewed = ({ questions, colorTheme }) => {
    const intl = useIntl();

    if (questions.length === 0) {
        return null;
    }

    return (
        <>
            <Divider
                text={intl.formatMessage({
                    id: 'mostviewed.divider',
                })}
                colorTheme={colorTheme}
                marginBottom="none"
            />

            <Grid
                justifyContent="center"
                childrenMargin="none"
                childrenMarginBig="none"
            >
                <Card hasBackground={false} blockWidth="xl">
                    <QuestionList
                        questions={questions}
                        colorTheme={colorTheme}
                    />
                </Card>
            </Grid>
        </>
    );
};

MostViewed.propTypes = {
    colorTheme: PropTypes.oneOf([
        'primary',
        'secondary',
        'tertiary',
        'quaternary',
        'quinary',
    ]),
};

MostViewed.defaultProps = {
    colorTheme: 'primary',
};

export default MostViewed;
