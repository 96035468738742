/**
 * Sort an array by priority, the null idem at lasts
 * @param {array} list
 */
const sortPrismicItemByPriority = list => {
    return list.sort((a, b) =>
        a.node.priority === null
            ? 1
            : b.node.priority === null
            ? -1
            : a.node.priority < b.node.priority
            ? -1
            : 1,
    );
};

export { sortPrismicItemByPriority };
