import React from 'react';
import PropTypes from 'prop-types';
import { Link as GatsbyLink } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import {
    Card,
    Text,
    Title,
    ButtonGroup,
    Button,
    List,
    ListItem,
    Link as PGLink,
} from '@paygreen/paygreen-ui';
import IconByName from '../components/IconByName';
import truncate from '../utils/truncater';

const margin = 'xs';
const internalSpace = 'xs';

const ClickableCard = ({
    name,
    description,
    icon,
    colorTheme,
    categories,
    link,
    maxListItems,
    borderTop,
}) => {
    const intl = useIntl();

    return (
        <Card
            colorTheme={colorTheme}
            blockWidth="xl"
            borderTop={borderTop ? borderTop : 'none'}
        >
            <Title
                htmlTag="h2"
                marginLateral={margin}
                marginTop={internalSpace}
                marginBottom={internalSpace}
                align="center"
                textSize="md"
            >
                {icon ? (
                    <IconByName
                        marginTop="md"
                        marginBottom="md"
                        isCentered={true}
                        colorTheme={colorTheme}
                        iconSize="md"
                        hasBackground={true}
                        name={icon}
                    />
                ) : null}

                {name}
            </Title>

            <Text
                marginLateral={margin}
                marginBottom={internalSpace}
                align="center"
                textSize="sm"
            >
                {truncate(description)}
            </Text>

            {categories.length > 0 ? (
                <Text htmlTag="div" marginLateral={margin}>
                    <List marginBottom="sm" colorTheme={colorTheme}>
                        {categories
                            .slice(0, maxListItems)
                            .map(({ _meta, name, parentTheme }) => (
                                <ListItem
                                    key={_meta.uid}
                                    colorTheme={colorTheme}
                                    bulletSize="xs"
                                >
                                    <GatsbyLink
                                        to={
                                            '/' +
                                            parentTheme._meta.uid +
                                            '/' +
                                            _meta.uid +
                                            '/'
                                        }
                                    >
                                        <PGLink
                                            colorTheme={colorTheme}
                                            hasUnderline={false}
                                        >
                                            {name}
                                        </PGLink>
                                    </GatsbyLink>
                                </ListItem>
                            ))}
                    </List>
                </Text>
            ) : null}

            <ButtonGroup marginBottom="sm" marginLateral={margin}>
                <GatsbyLink to={link.href}>
                    <Button colorTheme={colorTheme} buttonSize="sm">
                        {link.name
                            ? link.name
                            : intl.formatMessage({ id: 'go.to' })}
                    </Button>
                </GatsbyLink>
            </ButtonGroup>
        </Card>
    );
};

ClickableCard.propTypes = {
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    icon: PropTypes.string,
    /**
     * Color of the icon and title
     */
    theme: PropTypes.string,
    categories: PropTypes.array,
    maxListItems: PropTypes.number,
};

ClickableCard.defaultProps = {
    link: { name: null, href: '/' },
    categories: [],
    maxListItems: 3,
};

export default ClickableCard;
