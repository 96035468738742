import React from 'react';
import PropTypes from 'prop-types';
import {
    Banner as PGBanner,
    Grid,
    Card,
    Title,
    Text,
    Breadcrumb,
    Image,
} from '@paygreen/paygreen-ui';

const Banner = ({
    image,
    breadcrumb,
    name,
    description,
    theme,
    gradient,
    titleStyles,
}) => {
    return (
        <PGBanner
            gradient={gradient}
            colorTheme={theme}
            topStyle="none"
            isFirstContent={true}
        >
            <Grid>
                <Card hasBackground={false} blockWidth={image ? 'lg' : 'xl'}>
                    {breadcrumb ? (
                        <Breadcrumb
                            data-testid="banner-breadcrumb"
                            colorType="reverse"
                            elements={breadcrumb}
                        />
                    ) : null}

                    {name ? (
                        <Title
                            htmlTag="h1"
                            colorType="reverse"
                            textSize="xl"
                            marginTop={breadcrumb ? 'xs' : 'lg'}
                            hasUnderline
                            {...titleStyles}
                        >
                            {name}
                        </Title>
                    ) : null}

                    {description ? (
                        <Text colorType="reverse" textSize="md">
                            {description}
                        </Text>
                    ) : null}
                </Card>

                {image ? (
                    <Image className="hideOnTablet" blockWidth="xs">
                        <img src={image.url} alt={image.alt} />
                    </Image>
                ) : null}
            </Grid>
        </PGBanner>
    );
};

Banner.propTypes = {
    /**
     * @example
     * const breadcrumb = [
     * {url:'/', label:'Accueil',},
     * {url:'#', label:'Général',},
     * ];
     */
    breadcrumb: PropTypes.arrayOf(
        PropTypes.shape({
            url: PropTypes.string,
            label: PropTypes.string,
        }),
    ),
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    /**
     * Color of the title
     */
    theme: PropTypes.oneOf([
        'primary',
        'secondary',
        'tertiary',
        'quaternary',
        'quinary',
    ]),
};

Banner.defaultProps = {
    theme: 'primary',
};

export default Banner;
